import i18n from '@/i18n/index'
// import constant from '@/utils/constant.js'
// import { number } from 'echarts'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.common.cnName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.common.enName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'site_id',
      name: 'select',
      options: [],
      value: '',
      label: i18n.t('form.basicMessage.scenicSpot'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        key: 'id',
        value: 'id',
        filterable: true
      }
    }, {
      span: 8,
      prop: 'chope_options',
      name: 'select',
      options: [{
        name: i18n.t('form.basicMessage.supportChope'),
        value: '01'
      }, {
        name: i18n.t('form.basicMessage.multipleChopeId'),
        value: '02'
      }],
      value: '',
      label: i18n.t('form.basicMessage.supportChope'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'restaurant_id',
      name: 'input',
      label: i18n.t('form.basicMessage.chopeRestaurantID'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        visibleChange: [
          {chope_options: '01'}
        ]
      }
    }, {
      span: 24,
      prop: 'chope_id_list',
      name: 'array',
      value: [],
      label: i18n.t('form.basicMessage.chopeRestaurantID'),
      Object: {
        delete: '_destroy',
        visibleChange: [
          {chope_options: '02'}
        ]
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'name',
        name: 'input',
        label: i18n.t('form.basicMessage.chopeRestaurantName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
        }
      }, {
        span: 12,
        prop: 'chope_id',
        name: 'input',
        label: i18n.t('form.basicMessage.chopeID'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
        }
      }]
    }, {
      span: 8,
      prop: 'redeem_code',
      name: 'input',
      label: i18n.t('form.attractions.redemptionCode'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rules: [
          { pattern: new RegExp(/^[A-Za-z0-9]{4}$/, 'g'), message: i18n.t('message.redemptionCodeErrorMsg', { name: i18n.t('form.attractions.redemptionCode'), num: 4 }), trigger: 'blur' }
        ]
      }
    }, {
      span: 8,
      prop: 'lon',
      name: 'input',
      type: 'number',
      label: i18n.t('form.common.longitude'),
      placeholder: i18n.t('form.common.lontip'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'lat',
      name: 'input',
      type: 'number',
      label: i18n.t('form.common.latitude'),
      placeholder: i18n.t('form.common.lattip'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'title',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.titleCn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'title_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.titleEn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'description',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.descriptionCn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'description_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.descriptionEn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'rating',
      name: 'input',
      type: 'number',
      label: i18n.t('form.common.rating'),
      placeholder: i18n.t('0.0 - 5.0'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'address',
      name: 'input',
      label: i18n.t('form.common.address'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'website',
      name: 'input',
      label: i18n.t('form.attractions.website'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'contact_phone',
      name: 'input',
      label: i18n.t('form.attractions.contactNumber'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'email',
      name: 'input',
      label: i18n.t('form.common.mail'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rules: [
          { pattern: /^(.+?)@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/, message: i18n.t('placeholder.rightMail'), trigger: 'blur' }
        ]
      }
    }, {
      span: 8,
      prop: 'open',
      name: 'input',
      label: i18n.t('form.common.operationHoursCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'open_en',
      name: 'input',
      label: i18n.t('form.common.operationHoursEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'tips',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.remindersCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'tips_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.remindersEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'traffic_strategy',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.transportationGuideCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'traffic_strategy_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.transportationGuideEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'price',
      name: 'input',
      label: i18n.t('form.basicMessage.averagePrice'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }]
  },
  {
    title: i18n.t('form.common.image'),
    name: 1,
    createDateItem: [{
      span: 24,
      prop: 'images_attributes',
      name: 'image',
      value: [],
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 100,
        imageName: 'image_url'
      }
    }]
  },  {
    title: i18n.t('form.basicMessage.dishes'),
    name: 2,
    createDateItem: [{
      span: 24,
      prop: 'merchant_items_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.basicMessage.dishes'),
      Object: {
        delete: '_destroy'
      },
      createDateFirstItem: [{
        span: 24,
        prop: 'online',
        value: true,
        name: 'switch',
        label: i18n.t('form.product.online'),
        Object: {}
      }, {
        span: 12,
        prop: 'name',
        name: 'input',
        label: i18n.t('form.common.cnName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
        }
      }, {
        span: 12,
        prop: 'name_en',
        name: 'input',
        label: i18n.t('form.common.enName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
        }
      }, {
        span: 12,
        prop: 'price_f',
        name: 'input',
        type: 'number',
        label: i18n.t('form.product.price'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
        }
      }, {
        span: 24,
        prop: 'description',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.coupon.describe'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rows: 2
        }
      }, {
        span: 24,
        prop: 'image_url',
        name: 'image',
        value: [],
        label: i18n.t('form.common.image'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          limit: 1,
          imageName: 'image_url'
        }
      }]
    }]
  }]
}
