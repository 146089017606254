// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getRestaurantsEditDetails, putRestaurantsFormData, getAttractionsSimpleList } from '@/services/basic-msg.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      this.formData[0].createDateItem[3].Object.loading = true
      getAttractionsSimpleList().then(res => {
        this.formData[0].createDateItem[3].options = res.data.sites
        this.formData[0].createDateItem[3].Object.loading = false
      })
      getRestaurantsEditDetails(this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.modifyData = res.data.restaurant
          if (this.modifyData.chope === true) {
            this.modifyData.chope_options = '01'
          }
          if (this.modifyData.multiple_chope_id === true) {
            this.modifyData.chope_options = '02'
          }
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    removeArray (createData, obj) {
      if (createData[obj.prop][obj.index].id) {
        this.$set(createData[obj.prop][obj.index], obj.mark, true)
      } else {
        createData[obj.prop].splice(obj.index, 1)
      }
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        restaurant: obj
      }
      if (params.restaurant.chope_options === '01') {
        params.restaurant.chope = true
        params.restaurant.multiple_chope_id = false
      }
      if (params.restaurant.chope_options === '02') {
        params.restaurant.chope = false
        params.restaurant.multiple_chope_id = true
      }
      params.restaurant.merchant_items_attributes = params.restaurant.merchant_items_attributes.map(item => {
        return Object.assign(item, { remote_image_url: item.image_url })
      })
      putRestaurantsFormData(params, this.$route.params.id).then(res => {
        if (res.status === 200 || res.status === 204) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'restaurantsList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
